import shuffle from 'lodash.shuffle';
import React, { FunctionComponent, useEffect, useState } from 'react';
import HomeTileWall from '../components/home-tile-wall/home-tile-wall';
import Layout from '../components/layout/layout';
import MultiLangTags from '../components/multi-lang-tags/multi-lang-tags';
import OpenGraphTags from '../components/open-graph-tags/open-graph-tags';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import siteCover from '../images/site-cover.jpg';
import { Locale } from '../models/locale';
import { IMusicVideoEntry } from '../models/music-video-entry';
import { IReviewEntry } from '../models/review-entry';
import {
  fetchMusicVideos,
  fetchReviews,
  getMusicVideoCount,
  getReviewCounts,
} from '../services/cms';
import { buildCanonicalUrl } from '../utils/define';
import { useTranslation } from '../utils/i18n';
import styles from './index.module.scss';

interface IProps {
  pageContext: {
    intl: {
      language: Locale;
    };
  };
}

const getRandomIntInRange = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const reviewCountOnHomePage = 4;
const musicCountOnHomePage = 3;

const IndexPage: FunctionComponent<IProps> = ({ pageContext }) => {
  const { t } = useTranslation();
  const lang = pageContext.intl.language;
  const [tiles, setTiles] = useState<
    Array<{ type: 'music' | 'review'; entry: IMusicVideoEntry | IReviewEntry }>
  >([]);

  const fetchRandom = () => {
    Promise.all([getReviewCounts({ lang }), getMusicVideoCount({ lang })])
      .then(([reviewCount, videoCount]) => {
        const reviewSkip =
          reviewCount < reviewCountOnHomePage
            ? 0
            : getRandomIntInRange(0, reviewCount - reviewCountOnHomePage);
        const videoSkip =
          videoCount < musicCountOnHomePage
            ? 0
            : getRandomIntInRange(0, videoCount - musicCountOnHomePage);
        return { reviewSkip, videoSkip };
      })
      .then(({ reviewSkip, videoSkip }) => {
        return Promise.all([
          fetchReviews({ lang, count: reviewCountOnHomePage, skip: reviewSkip }),
          fetchMusicVideos({ lang, count: musicCountOnHomePage, skip: videoSkip }),
        ]);
      })
      .then(([reviews, videos]) => {
        const entries = shuffle([
          ...reviews.entries.map((e) => ({ type: 'review', entry: e })),
          ...videos.entries.map((e) => ({ type: 'music', entry: e })),
        ]);
        setTiles(entries);
      });
  };

  const onRefresh = () => fetchRandom();
  const pageDescription = t('common.description');

  useEffect(() => {
    fetchRandom();
  }, []);

  return (
    <Layout lang={pageContext.intl.language} basePath=''>
      <SeoMetaTags
        lang={pageContext.intl.language}
        title={`Beat Panda`}
        description={pageDescription}
        canonicalUrl={buildCanonicalUrl('', pageContext.intl.language)}
      />
      <MultiLangTags path={''} basePath={''} />
      <OpenGraphTags
        url={buildCanonicalUrl('', pageContext.intl.language)}
        title={`Beat Panda`}
        description={pageDescription}
        image={siteCover}
        locale={pageContext.intl.language}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={styles.titleBox}>
              <h1>{t('home.heading')}</h1>
              <h2>{t('home.sub_heading')}</h2>
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-12'>
            <HomeTileWall tiles={tiles} />
          </div>
          <div className='col-12 mt-5'>
            <div className={styles.titleBox}>
              <h2>
                <button onClick={onRefresh}>{t('home.refresh')}</button>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
