import React, { FunctionComponent, memo } from 'react';
import { IMusicVideoEntry } from '../../models/music-video-entry';
import { IReviewEntry } from '../../models/review-entry';
import HomeTile from '../home-tile/home-tile';

interface IProps {
  tiles: Array<{ type: 'music' | 'review'; entry: IMusicVideoEntry | IReviewEntry }>;
}

const HomeTileWall: FunctionComponent<IProps> = memo(({ tiles }) => {
  return (
    <>
      <div className='row'>
        {tiles[0] && (
          <div className='col-6 col-md-4 mt-5'>
            <HomeTile type={tiles[0].type} item={tiles[0].entry} />
          </div>
        )}
        {tiles[1] && (
          <div className='col-6 col-md-4 mt-5'>
            <HomeTile type={tiles[1].type} item={tiles[1].entry} />
          </div>
        )}
        {tiles[2] && (
          <div className='col-12 col-md-4 mt-5'>
            <HomeTile type={tiles[2].type} item={tiles[2].entry} />
          </div>
        )}
      </div>
      <div className='row'>
        {tiles[3] && (
          <div className='col-6 col-md-4 mt-5'>
            <HomeTile type={tiles[3].type} item={tiles[3].entry} />
          </div>
        )}
        {tiles[4] && (
          <div className='col-6 col-md-8 mt-5'>
            <HomeTile type={tiles[4].type} item={tiles[4].entry} />
          </div>
        )}
      </div>
      <div className='row'>
        {tiles[5] && (
          <div className='col-12 col-md-8 mt-5'>
            <HomeTile type={tiles[5].type} item={tiles[5].entry} />
          </div>
        )}
        {tiles[6] && (
          <div className='col-12 col-md-4 mt-5'>
            <HomeTile type={tiles[6].type} item={tiles[6].entry} />
          </div>
        )}
      </div>
    </>
  );
});

export default HomeTileWall;
