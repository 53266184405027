import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { IMusicVideoEntry } from '../../models/music-video-entry';
import { IReviewEntry } from '../../models/review-entry';
import { buildArticleDatePath } from '../../utils/define';
import { Link } from '../../utils/i18n';
import styles from './home-tile.module.scss';

type ItemType = IMusicVideoEntry | IReviewEntry;

interface IProps {
  type: 'review' | 'music';
  item: ItemType;
}

const HomeTile: FunctionComponent<IProps> = memo(({ type, item }) => {
  const [tileItems, setTileItems] = useState<ItemType[]>([]);
  const [transTime, setTransTime] = useState(Math.floor(Math.random() * 2) + 1);
  const [switched, setSwitched] = useState(false);

  const style = {
    transitionDuration: `${transTime}s`,
  };

  const getToUrl = (tile: ItemType) => {
    if (type === 'review') {
      const date = new Date(tile.createdAt);
      return `${buildArticleDatePath(date)}/${tile.slug}-${tile.lang}/`;
    } else if (type === 'music') {
      return `/music/${tile.slug}-${tile.lang}/`;
    }
    return '#';
  };

  const getBackgroundImage = (i: ItemType): string => {
    const base = i.cover || (i as IMusicVideoEntry).thumbnail;
    return `url(${base}?w=960&fl=progressive&q=80)`;
  };

  useEffect(() => {
    let newTileItems = [...tileItems, item];
    if (newTileItems.length >= 3) {
      newTileItems = newTileItems.slice(1);
    }
    setTileItems(newTileItems);
    setSwitched(false);
    setTransTime(Math.floor(Math.random() * 2) + 1);
    if (newTileItems.length >= 2) {
      setTimeout(() => setSwitched(true), 250);
    }
  }, [item.id]);

  return (
    <div className={styles.homeTile}>
      {tileItems.slice(tileItems.length - 2).map((tile, index) => {
        return (
          <Link key={tile.id} to={getToUrl(tile)}>
            <div
              className={`${styles.tileContent} ${index === 1 ? styles.bottom : styles.top} ${
                switched ? styles.switched : ''
              }`}
              style={{
                ...style,
                backgroundImage: getBackgroundImage(tile),
              }}
            >
              {type === 'music' && <i className={`fas fa-headphones ${styles.typeIcon}`} />}
              {type === 'review' && <i className={`fas fa-quote-left ${styles.typeIcon}`} />}
              {type === 'music' && <div className={styles.musicMask}>{tile.title}</div>}
              {type === 'review' && <div className={styles.reviewMask}>{tile.title}</div>}
            </div>
          </Link>
        );
      })}
    </div>
  );
});

export default HomeTile;
